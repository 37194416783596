import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

export const InputDatePicker = ({ label, format, disabled, currentValue, fullWidth, setValue, ...props }) => {
  const isDisabled = disabled ?? false;

  let it = moment().locale('it');

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={it}>
      <DatePicker
        id={props.id + '-input'}
        key={props.key + '-input'}
        value={currentValue}
        format={format}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        disabled={isDisabled}
        className="bg-white text-xs w-full"
        slotProps={{
          textField: {
            variant: 'outlined',
            name: 'datepicker-text-field-' + name,
            key: 'datepicker-text-field-' + name,
            label: label,
            fullWidth: fullWidth ?? false
          }
        }}
        fullWidth={fullWidth ?? false}
        {...props}
      />
    </LocalizationProvider>
  );
};
