export const navItems = [
  {
    id: 'home',
    title: 'Home',
    description: '',
    type: 'item',
    icon: 'home',
    url: 'Home',
    children: []
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    description: 'Dashboard',
    type: 'group',
    icon: 'chart',
    children: [
      {
        id: 'locale',
        title: 'Locale',
        description:
          'Contiene tutte le spedizioni gestite dal deposito D1 che hanno come record “Tipo Corriere” = 4 e “Altro tipo corriere“ = “PAD“',
        type: 'item',
        icon: 'chart',
        url: 'Dashboards/Local',
        target: false
      },
      {
        id: 'lazio',
        title: 'Lazio',
        description:
          'Contiene tutte le spedizioni gestite dal deposito D1 che hanno come record “Tipo Corriere” = 4 oppure “Ragione Destino” = Lazio e che sono state gestite esclusivamente dai corrieri riportati nella tabella',
        type: 'item',
        icon: 'chart',
        url: 'Dashboards/Regional',
        target: false
      },
      {
        id: 'nazionale',
        title: 'Nazionale',
        description: 'Contiene tutte le spedizioni gestite dal deposito D1 che hanno come record “Tipo Corriere” <> 4',
        type: 'item',
        icon: 'chart',
        url: 'Dashboards/National',
        target: false
      },
      {
        id: 'generale',
        title: 'Generale',
        description: 'Contiene tutte le spedizioni gestite dal deposito D1',
        type: 'item',
        icon: 'chart',
        url: 'Dashboards/General',
        target: false
      },
      {
        id: 'totali',
        title: 'Dati totali',
        description: 'Contiene tutte le spedizioni gestite dal deposito D1 in formato tabella',
        type: 'item',
        icon: 'grid',
        url: 'Dashboards/Grid',
        target: false
      }
    ]
  },
  {
    id: 'settings',
    title: 'Impostazioni',
    type: 'group',
    icon: 'settings',
    children: [
      {
        id: 'users',
        title: 'Utenti',
        type: 'item',
        icon: 'account',
        url: 'Registries/Users',
        target: false
      },
      {
        id: 'corrieri',
        title: 'Corrieri Lazio',
        type: 'item',
        icon: 'warehouse',
        url: 'Registries/Carriers',
        target: false
      }
    ]
  }
];
