import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import LoggedLayout from 'layout/Logged';

import Users from '../pages/logged/registries/Users';
import { DomainContainer } from './LocationRenderer';
import Carriers from '../pages/logged/registries/Carriers';
import DashboardPage from '../pages/logged/dashboards/DahsboardPage';
import { defaultFilterOptions } from '../store/reducers/filter';

const HomePage = Loadable(lazy(() => import('pages/logged/Home')));

const registriesRoutes = [
  { path: '/Registries/Users', component: <Users />, exact: true },
  { path: '/Registries/Carriers', component: <Carriers />, exact: true }
];

const dashboardPages = [
  {
    id: 'local',
    source: 'local',
    path: '/Dashboards/Local',
    options: {
      ...defaultFilterOptions,
      hasDateFields: false,
      hasCarrierType: false,
      hasRegion: false,
      hasDownload: false
    }
  },
  {
    id: 'regional',
    source: 'regional',
    path: '/Dashboards/Regional',
    options: {
      ...defaultFilterOptions,
      hasDateFields: false,
      hasBranch: false,
      hasCarrierType: false,
      hasRegion: false,
      hasDownload: false
    }
  },
  {
    id: 'national',
    source: 'national',
    path: '/Dashboards/National',
    options: {
      ...defaultFilterOptions,
      hasDateFields: false,
      hasBranch: false,
      hasDownload: false
    }
  },
  {
    id: 'general',
    source: 'general',
    path: '/Dashboards/General',
    options: {
      ...defaultFilterOptions,
      hasDateFields: false,
      hasBranch: false,
      hasDownload: false
    }
  },
  {
    id: 'grid',
    source: 'grid',
    path: '/Dashboards/Grid',
    options: {
      ...defaultFilterOptions
    }
  }
];

const dashboardRoutes = dashboardPages.map((pageItem) => {
  return {
    path: pageItem.path,
    component: <DashboardPage source={pageItem.source} options={pageItem.options} key={pageItem.id} />,
    exact: true
  };
});

const LoggedRoutes = {
  path: '/',
  element: <LoggedLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: '/Home',
      element: <HomePage />
    },
    {
      path: '/Dashboards/*',
      element: <DomainContainer title={'Dashboard'} routes={dashboardRoutes} />
    },
    {
      path: '/Registries/*',
      element: <DomainContainer title={'Anagrafiche'} routes={registriesRoutes} />
    }
  ]
};

export default LoggedRoutes;
