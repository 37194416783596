import { useLocation } from 'react-router-dom';
import React from 'react';
import { Card, CardContent, useTheme } from '@mui/material';
import { loggedClassNames } from '../themes/Styles';
import { ContainerTitle } from '../components/layout/ContainerTitle';

export const LocationRenderer = ({ routes }) => {
  const location = useLocation();

  const exactElement = routes.find((l) => l.exact === true && location.pathname === l.path);

  if (exactElement) return exactElement.component;

  const partialElement = routes.find((l) => l.exact === false && location.pathname.startsWith(l.path));

  return partialElement ? partialElement.component : <></>;
};

export const DomainContainer = ({ title, routes }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  return (
    <Card sx={loggedClasses.loggedContent}>
      <CardContent sx={loggedClasses.loggedCardContent}>
        <ContainerTitle title={title} key={'container-' + title} />
        <LocationRenderer routes={routes} />
      </CardContent>
    </Card>
  );
};
