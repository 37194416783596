// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import { useSelector } from 'react-redux';
import React from 'react';
import { navItems } from '../../../../../routes/LoggedNav';
import NavItem from './NavItem';

const Navigation = () => {
  const authStatus = useSelector((state) => state.auth.authStatus);

  if (!authStatus.isLogged)
    return (
      <Typography key={'no-logged-label'} variant="h6" color="error" align="center">
        No menus
      </Typography>
    );

  const navGroups = navItems.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
