import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const UserImage = ({ width, height }) => {
  const theme = useTheme();
  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};

  const completeName = loggedUser.name ?? '-';

  const stringAvatar = (name, width, height) => {
    return {
      sx: {
        backgroundColor: theme.palette.info.dark,
        width: width,
        height: height
      },
      children: `${name[0].toUpperCase()}`
    };
  };

  return <Avatar {...stringAvatar(completeName, width, height)} />;
};

export default UserImage;
