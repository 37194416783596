import React from 'react';
import { Box, Button, styled, Tooltip, useTheme } from '@mui/material';
import { ButtonIcon } from '../icons/ButtonIcon';

export const StyledContainerHeader = ({ ...props }) => {
  const theme = useTheme();

  const StyledComponent = styled(Box)({
    ...theme.typography.h5,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(1)
  });

  return <StyledComponent>{props.children}</StyledComponent>;
};

export const CustomTitleButton = ({ customAction, customDisabled, customIcon, customTooltip, ...props }) => {
  let isDisabled = customDisabled ?? false;
  let sTooltip = customTooltip ?? '';
  let hasTooltip = !isDisabled && sTooltip !== '';

  const theme = useTheme();

  const StyledTitleButton = styled(Button)({
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    boxShadow: 'none !important'
  });

  const buttonClicked = () => {
    if (customAction) customAction();
  };

  const getButton = () => {
    return (
      <StyledTitleButton onClick={buttonClicked} disabled={customDisabled} {...props}>
        <ButtonIcon icon={customIcon} iconSize={'medium'} />
      </StyledTitleButton>
    );
  };

  return !hasTooltip ? (
    <>{getButton()}</>
  ) : (
    <Tooltip title={sTooltip}>
      <span>{getButton()}</span>
    </Tooltip>
  );
};

const ContainerTitle = ({ title, hasHome, onHomeClick, customActions, ...props }) => {
  const bHome = hasHome ?? false;
  const arrCustomActions = customActions ?? [];

  return (
    <StyledContainerHeader>
      {bHome ? (
        <CustomTitleButton customAction={onHomeClick} disabled={false} customIcon={'home'} customTooltip={'Home'} {...props} />
      ) : (
        <></>
      )}
      {arrCustomActions.map((item) => {
        return (
          <CustomTitleButton
            key={item.action}
            customAction={item.action}
            disabled={item.disabled ?? false}
            customIcon={item.icon}
            customTooltip={item.tooltip}
            {...props}
          />
        );
      })}
      {title}
    </StyledContainerHeader>
  );
};

export { ContainerTitle };
