import { Box, styled, useTheme } from '@mui/material';
import React from 'react';

export const StyledCardHeader = ({ ...props }) => {
  const theme = useTheme();

  const StyledComponent = styled(Box)({
    ...theme.typography.h4,
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(0.1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.primary.contrastText
  });

  return <StyledComponent>{props.children}</StyledComponent>;
};
