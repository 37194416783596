import { TextField } from '@mui/material';
import React from 'react';

export const InputTextBox = ({ disabled, label, currentValue, setValue, fullWidth, ...props }) => {

  return (
    <TextField
      id={props.id + '-input'}
      key={props.key + '-input'}
      variant={'outlined'}
      label={label}
      value={currentValue}
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled ?? false}
      fullWidth={fullWidth ?? false}
      {...props}
    />
  );
};
