import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { ButtonIcon } from '../icons/ButtonIcon';

export const ToolButton = ({ color, variant, action, size, icon, iconSize, tooltip, disabled, ...otherProps }) => {
  let isDisabled = disabled ?? false;
  let sTooltip = tooltip ?? '';
  let hasTooltip = !isDisabled && sTooltip !== '';

  const getButton = () => {
    return (
      <IconButton
        color={color}
        variant={variant}
        onClick={(e) => {
          e.preventDefault();
          action();
        }}
        size={size}
        disabled={isDisabled}
        {...otherProps}
      >
        <ButtonIcon icon={icon} iconSize={iconSize} />
      </IconButton>
    );
  };

  return !hasTooltip ? (
    <>{getButton()}</>
  ) : (
    <Tooltip title={tooltip}>
      <div>{getButton()}</div>
    </Tooltip>
  );
};
