import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  fetchBranches,
  fetchCarriers,
  fetchCarrierTypes,
  fetchContracts,
  fetchCustomers,
  fetchDateFields,
  fetchDistricts,
  fetchRegions
} from '../../store/reducers/registries';
import { ActionButton } from '../buttons/ActionButton';
import { isDataEmpty, isDataError, isDataLoading } from '../../utils/common';
import { InputAutoComplete } from '../input/InputAutocomplete';
import { Grid, Stack } from '@mui/material';
import { InputTextBox } from '../input/InputTextBox';
import { useTheme } from '@mui/material/styles';
import { InputDatePicker } from '../input/InputDatePicker';
import { InputFilterRuleSwitch } from '../input/InputFilterRuleSwitch';

export default function DashboardFilterbar({ options, filter, setFilter, getDataHandler, resetFilterHandler, downloadDataHandler }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const branches = useSelector((state) => state.registries.branches.records);
  const branchesOptions = useSelector((state) => state.registries.branches.options);
  const carriers = useSelector((state) => state.registries.carriers.records);
  const carriersOptions = useSelector((state) => state.registries.carriers.options);
  const carrierTypes = useSelector((state) => state.registries.carrierTypes.records);
  const carrierTypesOptions = useSelector((state) => state.registries.carrierTypes.options);
  const contracts = useSelector((state) => state.registries.contracts.records);
  const contractsOptions = useSelector((state) => state.registries.contracts.options);
  const customers = useSelector((state) => state.registries.customers.records);
  const customersOptions = useSelector((state) => state.registries.customers.options);
  const dateFields = useSelector((state) => state.registries.dateFields.records);
  const dateFieldsOptions = useSelector((state) => state.registries.dateFields.options);
  const districts = useSelector((state) => state.registries.districts.records);
  const districtsOptions = useSelector((state) => state.registries.districts.options);
  const regions = useSelector((state) => state.registries.regions.records);
  const regionsOptions = useSelector((state) => state.registries.regions.options);

  const [localFilter, setLocalFilter] = useState(filter);

  useEffect(() => {
    const payload = { body: {}, dispatch: dispatch };
    if (!isDataEmpty(branches) || isDataLoading(branches) || isDataError(branches)) return;
    dispatch(fetchBranches(payload));
    if (!isDataEmpty(carriers) || isDataLoading(carriers) || isDataError(carriers)) return;
    dispatch(fetchCarriers(payload));
    if (!isDataEmpty(carrierTypes) || isDataLoading(carrierTypes) || isDataError(carrierTypes)) return;
    dispatch(fetchCarrierTypes(payload));
    if (!isDataEmpty(contracts) || isDataLoading(contracts) || isDataError(contracts)) return;
    dispatch(fetchContracts(payload));
    if (!isDataEmpty(customers) || isDataLoading(customers) || isDataError(customers)) return;
    dispatch(fetchCustomers(payload));
    if (!isDataEmpty(dateFields) || isDataLoading(dateFields) || isDataError(dateFields)) return;
    dispatch(fetchDateFields(payload));
    if (!isDataEmpty(districts) || isDataLoading(districts) || isDataError(districts)) return;
    dispatch(fetchDistricts(payload));
    if (!isDataEmpty(regions) || isDataLoading(regions) || isDataError(regions)) return;
    dispatch(fetchRegions(payload));
  }, [dispatch, branches, carriers, contracts, customers, districts, carrierTypes, regions, dateFields]);

  const getData = () => {
    setFilter(localFilter);
    getDataHandler();
  };

  const resetFilter = () => {
    resetFilterHandler();
  };

  const downloadData = () => {
    setFilter(localFilter);
    downloadDataHandler();
  };

  const changeFilter = (newFilter) => {
    setLocalFilter({ ...localFilter, ...newFilter });
  };

  return (
    <Grid container spacing={theme.spacing(1)} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
      {options.hasShipment && (
        <Grid item xs={6} lg={2}>
          <InputTextBox
            label="Spedizione"
            currentValue={localFilter.shipment}
            setValue={(newValue) => {
              changeFilter({ shipment: newValue });
            }}
            maxLength={50}
            disabled={false}
            fullWidth={true}
          />
        </Grid>
      )}
      {options.hasDateFields && (
        <Grid item xs={6} lg={3}>
          <InputAutoComplete
            multiple={false}
            id="date-field-autocomplete"
            key="date-field-autocomplete"
            label={'Filtro data'}
            options={dateFieldsOptions}
            value={localFilter.dateField}
            setValue={(item) => {
              changeFilter({ dateField: item });
            }}
            disabled={false}
            fullWidth={true}
          />
        </Grid>
      )}
      <Grid item xs={6} lg={2}>
        <InputDatePicker
          label={'Dal'}
          openTo="day"
          format={'DD/MM/YYYY'}
          currentValue={localFilter.beginDate}
          setValue={(newValue) => {
            changeFilter({ beginDate: newValue.clone() });
          }}
          disabled={false}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <InputDatePicker
          label={'Al'}
          openTo="day"
          format={'DD/MM/YYYY'}
          currentValue={localFilter.endDate}
          setValue={(newValue) => {
            changeFilter({ endDate: newValue.clone() });
          }}
          disabled={false}
          fullWidth={true}
        />
      </Grid>
      {options.hasBranch && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="branches-switch"
              key="branches-switch"
              label={'Escl./Incl.'}
              value={localFilter.branchRule}
              setValue={(newValue) => {
                changeFilter({ branchRule: newValue });
              }}
            />
            <InputAutoComplete
              id="branches-autocomplete"
              key="branches-autocomplete"
              label={'Filiale'}
              options={branchesOptions}
              value={localFilter.branch}
              setValue={(item) => {
                changeFilter({ branch: item });
              }}
              disabled={false}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      {options.hasCarrier && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="carriers-switch"
              key="carriers-switch"
              label={'Escl./Incl.'}
              value={localFilter.carrierRule}
              setValue={(newValue) => {
                changeFilter({ carrierRule: newValue });
              }}
            />
            <InputAutoComplete
              id="carriers-autocomplete"
              key="carriers-autocomplete"
              options={carriersOptions}
              value={localFilter.carrier}
              setValue={(item) => {
                changeFilter({ carrier: item });
              }}
              disabled={false}
              label={'Corriere'}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      {options.hasCarrierType && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="carrier-types-switch"
              key="carrier-types-switch"
              label={'Escl./Incl.'}
              value={localFilter.carrierTypeRule}
              setValue={(newValue) => {
                changeFilter({ carrierTypeRule: newValue });
              }}
            />
            <InputAutoComplete
              id="carrier-types-autocomplete"
              key="carrier-types-autocomplete"
              options={carrierTypesOptions}
              value={localFilter.carrierType}
              setValue={(item) => {
                changeFilter({ carrierType: item });
              }}
              disabled={false}
              label={'Tipo Corriere'}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      {options.hasCustomer && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="customer-switch"
              key="customer-switch"
              label={'Escl./Incl.'}
              value={localFilter.customerRule}
              setValue={(newValue) => {
                changeFilter({ customerRule: newValue });
              }}
            />
            <InputAutoComplete
              id="customers-autocomplete"
              key="customers-autocomplete"
              options={customersOptions}
              value={localFilter.customer}
              setValue={(item) => {
                changeFilter({ customer: item });
              }}
              disabled={false}
              label={'Cliente'}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      {options.hasDistrict && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="district-switch"
              key="district-switch"
              label={'Escl./Incl.'}
              value={localFilter.districtRule}
              setValue={(newValue) => {
                changeFilter({ districtRule: newValue });
              }}
            />
            <InputAutoComplete
              id="districts-autocomplete"
              key="districts-autocomplete"
              options={districtsOptions}
              value={localFilter.district}
              setValue={(item) => {
                changeFilter({ district: item });
              }}
              disabled={false}
              label={'Provincia cliente'}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      {options.hasRegion && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="region-switch"
              key="region-switch"
              label={'Escl./Incl.'}
              value={localFilter.regionRule}
              setValue={(newValue) => {
                changeFilter({ regionRule: newValue });
              }}
            />
            <InputAutoComplete
              id="regions-autocomplete"
              key="regions-autocomplete"
              options={regionsOptions}
              value={localFilter.region}
              setValue={(item) => {
                changeFilter({ region: item });
              }}
              disabled={false}
              label={'Regione cliente'}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      {options.hasContract && (
        <Grid item xs={6} lg={3}>
          <Stack direction={'row'} spacing={theme.spacing(0.5)}>
            <InputFilterRuleSwitch
              id="contract-switch"
              key="contract-switch"
              label={'Escl./Incl.'}
              value={localFilter.contractRule}
              setValue={(newValue) => {
                changeFilter({ contractRule: newValue });
              }}
            />
            <InputAutoComplete
              id="contracts-autocomplete"
              key="contracts-autocomplete"
              options={contractsOptions}
              value={localFilter.contract}
              setValue={(item) => {
                changeFilter({ contract: item });
              }}
              disabled={false}
              label={'Contratto'}
              fullWidth={true}
            />
          </Stack>
        </Grid>
      )}
      <Grid item xs={6} lg={3}>
        <Stack direction={'row'} spacing={theme.spacing(1)}>
          <ActionButton
            color="primary"
            variant="contained"
            action={() => {
              getData();
            }}
            size="small"
            icon={'cloud-download'}
            iconSize="small"
            tooltip={'Carica'}
            label={''}
            disabled={false}
            loading={false}
          />
          <ActionButton
            color="primary"
            variant="contained"
            action={() => {
              resetFilter();
            }}
            size="small"
            icon={'refresh'}
            iconSize="small"
            tooltip={'Reset'}
            label={''}
            disabled={false}
            loading={false}
          />
          {options.hasDownload && (
            <ActionButton
              color="primary"
              variant="contained"
              action={() => {
                downloadData();
              }}
              size="small"
              icon={'download'}
              iconSize="small"
              tooltip={'Scarica'}
              label={''}
              disabled={false}
              loading={false}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
