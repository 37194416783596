import { useTheme } from '@mui/material/styles';
import DashboardFilterbar from '../../../components/dashboard/DashboardFilterbar';
import { Grid } from '@mui/material';
import ShipmentsChart from '../../../components/dashboard/ShipmentsChart';
import {
  dashboard_charts,
  dashboard_grid_columns,
  getAllDataCSV,
  getAllDataRows,
  getChartData
} from '../../../store/reducers/dashboard';
import {
  ALL_VALUE,
  columnTypes,
  defaultColDef,
  downloadCsvListOfByte,
  intDateFormat,
  JSONClone,
  LOADING
} from '../../../utils/common';
import React, { useEffect, useState } from 'react';
import { initFilter } from '../../../store/reducers/filter';
import { useDispatch } from 'react-redux';
import { gridRowHeight, gridSizeStyle } from '../../../themes/Styles';
import { AgGridReact } from 'ag-grid-react';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { showCustomAlert } from '../../../store/reducers/errors';

const DashboardPage = ({ source, options }) => {
  const theme = useTheme();
  const init_charts = JSONClone(dashboard_charts).filter((ch) => ch.pages.includes(source));
  const dispatch = useDispatch();

  let init_filter = JSONClone(initFilter);

  if (source === 'local') {
    init_filter.zone = 'Locale';
  } else if (source === 'regional') {
    init_filter.zone = 'Lazio';
  } else if (source === 'national') {
    init_filter.zone = 'Nazionale';
  } else if (source === 'general') {
    init_filter.zone = ALL_VALUE;
  } else if (source === 'grid') {
    init_filter.zone = ALL_VALUE;
  }

  const [filter, setFilter] = useState(init_filter);
  const [charts, setCharts] = useState(init_charts);
  const [loaded, setLoaded] = useState(false);
  const [chartsData, setChartsData] = useState([]);
  const [toLoad, setToLoad] = useState(false);
  const [records, setRecords] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (loaded) return;
    setCharts(init_charts);
    setFilter(init_filter);
    setLoaded(true);
  }, [init_charts, init_filter, loaded]);

  useEffect(() => {
    if (chartsData.length < charts.length) return;
    let newCharts = [...charts];
    charts.map((chart, index) => {
      const foundItem = chartsData.find((item) => item.index === chart.index);
      if (foundItem) newCharts[index].data = foundItem.data;
    });
    setCharts(newCharts);
    setChartsData([]);
  }, [charts, chartsData, dispatch, filter]);

  useEffect(() => {
    if (!toLoad) return;
    if (source !== 'grid') {
      let newCharts = [...charts];
      charts.map((chart, index) => {
        newCharts[index].data = LOADING;
      });
      setCharts(newCharts);
      let newChartData = [];
      charts.map((chart) => {
        const payload = {
          chart: chart,
          filter: filter,
          dispatch: dispatch
        };
        dispatch(getChartData(payload)).then((res) => {
          newChartData.push({ index: chart.index, data: res.payload.result.responseData });
          if (newChartData.length >= charts.length) setChartsData(newChartData);
        });
      });
    } else {
      if (!filter.dateField) {
        const message = {
          severity: 'error',
          message: 'Selezionare un campo data'
        };
        dispatch(showCustomAlert(message));
        setToLoad(false);
        return;
      }
      const payload = {
        filter: filter,
        dispatch: dispatch
      };
      setIsDownloading(true);
      dispatch(getAllDataRows(payload)).then((res) => {
        setRecords(res.payload.result.responseData);
        setIsDownloading(false);
      });
    }
    setToLoad(false);
  }, [charts, dispatch, filter, source, toLoad]);

  const getDataHandler = () => {
    setToLoad(true);
  };

  const resetFilterHandler = () => {
    setCharts(init_charts);
    setFilter(init_filter);
    setRecords([]);
  };

  const downloadDataHandler = () => {
    const payload = {
      filter: filter,
      dispatch: dispatch
    };
    const dateTime = filter.beginDate.format(intDateFormat);
    setIsDownloading(true);
    dispatch(getAllDataCSV(payload)).then((res) => {
      if (!getAllDataCSV.fulfilled.match(res)) return null;
      const csv = res.payload.result.responseData ?? '';
      const fileName = `SpedizioniTotali_${dateTime}.csv`;
      downloadCsvListOfByte(csv, fileName);
      setIsDownloading(false);
    });
  };

  const [columns] = useState([...dashboard_grid_columns]);

  return (
    <>
      <Grid
        container
        spacing={theme.spacing(1)}
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        sx={{ py: theme.spacing(1) }}
      >
        <Grid item xs={12}>
          <DashboardFilterbar
            options={options}
            source={source}
            filter={filter}
            setFilter={setFilter}
            getDataHandler={getDataHandler}
            resetFilterHandler={resetFilterHandler}
            downloadDataHandler={downloadDataHandler}
          />
        </Grid>
        {isDownloading ? (
          <Grid item xs={12}>
            <CircularWaiting />
          </Grid>
        ) : (
          <>
            {source !== 'grid' && (
              <Grid item xs={12}>
                <Grid container spacing={theme.spacing(1)}>
                  {charts.map((chart, index) => {
                    return (
                      <Grid item xs={12} md={12} lg={chart.columns} key={`chart-col-${index}`}>
                        <ShipmentsChart key={`chart-${index}`} chart={chart} filter={filter} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {source === 'grid' && (
              <Grid item xs={12}>
                <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                  <AgGridReact
                    rowData={records}
                    defaultColDef={defaultColDef}
                    columnDefs={columns}
                    columnTypes={columnTypes}
                    suppressMovableColumns={true}
                    suppressCellFocus={true}
                    pagination={true}
                    rowSelection={'single'}
                    getRowHeight={() => gridRowHeight}
                  />
                </div>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default DashboardPage;
