import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import { ADMIN_PROFILE, GUEST_PROFILE, JSONClone } from '../../utils/common';
import {
  AXIOS_CALL_RESULT_SESSION_KEY,
  AXIOS_CONFIG_SESSION_KEY,
  getSessionValue,
  LOGGED_USER_SESSION_KEY,
  setSessionValue
} from '../../utils/storage';

export const initAuthRecord = {};

const maxSessionTime = process.env.REACT_APP_SESSION_DURATION * 60 * 1000;

const sessionCheckInterval = process.env.REACT_APP_SESSION_CHECK * 1000;

const session = getSessionValue(LOGGED_USER_SESSION_KEY, '');

const DefaultAuthStatus = {
  isLogged: false,
  isExpired: false,
  loggedUser: {},
  sessionRemainingTime: 0
};

const ExpiredAuthStatus = {
  isLogged: false,
  isExpired: true,
  loggedUser: {},
  responseMessage: '',
  sessionRemainingTime: 0
};

const DefaultLoginStatus = {
  username: '',
  password: ''
};

// initial state
let initialState = {
  authStatus: JSONClone(DefaultAuthStatus),
  loginStatus: JSONClone(DefaultLoginStatus),
  maxSessionTime: maxSessionTime,
  sessionCheckInterval: sessionCheckInterval,
  encryptedAuthRes: null
};

if (session !== '') {
  initialState.authStatus = {
    ...initialState.authStatus,
    isLogged: true,
    loggedUser: session
  };
}

export const login = createAsyncThunk('auth/login', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  setSessionValue(LOGGED_USER_SESSION_KEY, '');
  try {
    const res = await DoWebApiPostCall('/Users/Login', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSessionExpired(state) {
      state.authStatus = JSONClone(ExpiredAuthStatus);
      setSessionValue(LOGGED_USER_SESSION_KEY, '');
      setSessionValue(AXIOS_CONFIG_SESSION_KEY, '');
      setSessionValue(AXIOS_CALL_RESULT_SESSION_KEY, '');
    },
    setSessionRemainingTime(state, action) {
      state.authStatus = {
        ...state.authStatus,
        sessionRemainingTime: action.payload.remainingTime
      };
    },
    doLogout(state) {
      state.authStatus = {
        ...JSONClone(DefaultAuthStatus)
      };
      setSessionValue(LOGGED_USER_SESSION_KEY, '');
      setSessionValue(AXIOS_CONFIG_SESSION_KEY, '');
      setSessionValue(AXIOS_CALL_RESULT_SESSION_KEY, '');
    },
    isAdminLogged(state) {
      return ((state.authStatus.loggedUser ?? {}).role ?? '') === ADMIN_PROFILE;
    },
    isGuestLogged(state) {
      return ((state.authStatus.loggedUser ?? {}).role ?? '') === GUEST_PROFILE;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.authStatus = {
        ...JSONClone(DefaultAuthStatus)
      };
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const res = payload.result;
      if (res.responseData) {
        setSessionValue(LOGGED_USER_SESSION_KEY, res.responseData);
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: true,
          loggedUser: res.responseData,
          responseMessage: res.responseMessage,
          sessionRemainingTime: maxSessionTime
        };
      } else {
        setSessionValue(LOGGED_USER_SESSION_KEY, '');
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: false,
          loggedUser: {},
          sessionRemainingTime: 0
        };
      }
    });
  }
});

export const { setSessionExpired, setSessionRemainingTime, doLogout, isAdminLogged, isGuestLogged } = auth.actions;
export default auth.reducer;
