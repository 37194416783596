// material-ui
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';

// project import
import ProfileMenu from './ProfileMenu';
import MobileSection from './MobileSection';
import SessionProgress from '../../../../components/sessionProgress/SessionProgress';
import React, { useEffect, useState } from 'react';
import AppLogo from '../../../../components/logo/AppLogo';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { CircleButton } from '../../../../components/buttons/CircleButton';
import { doLogout } from '../../../../store/reducers/auth';
import { useNavigate } from 'react-router-dom';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authStatus = useSelector((state) => state.auth.authStatus);
  const { openTitle, openDescription } = useSelector((state) => state.menu);

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [logoutDone, setLogoutDone] = useState(false);

  useEffect(() => {
    if (logoutDone) navigate('/login');
  }, [authStatus, logoutDone, navigate]);

  const handleLogout = async () => {
    const payload = { body: {}, dispatch: dispatch };
    dispatch(doLogout(payload));
    setLogoutDone(true);
  };

  return (
    <>
      {/*{!matchesXs && <GoToDesktop />}*/}
      {!matchesXs && (
        <Box sx={{ ml: { xs: 0, md: 1 } }}>
          <AppLogo direction={'column'} size={35} />
        </Box>
      )}
      {matchesXs && <Box sx={{ width: '100%', ml: 2, px: 2 }} />}
      {
        <Stack direction={'column'} spacing={theme.spacing(0)} sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
          <Typography variant={'h6'}>{openTitle}</Typography>
          {!matchesXs && <Typography variant={'body1'}>{openDescription}</Typography>}
        </Stack>
      }
      {!matchesXs && <ProfileMenu />}
      <SessionProgress />
      <CircleButton
        color="primary"
        variant="contained"
        action={handleLogout}
        size="extra-small"
        icon={'logout'}
        iconSize="extra-small"
        tooltip={'Vai al desktop'}
        label={''}
        disabled={false}
        loading={false}
      />
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
