// ==============================|| SLICE - AUTH ||============================== //

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import {
  ALL_VALUE,
  booleanRenderer,
  dateColumnRenderer,
  getMultipleSelectionString,
  getSingleSelectionString,
  INIT_DATE_VALUE,
  longDateTimeFormat
} from '../../utils/common';

export const dashboard_charts = [
  {
    index: 0,
    pages: ['local', 'regional', 'national', 'general'],
    category: 'Tempi consegna da affidamento',
    columns: 6,
    config: {
      xAxisField: 'Giorni Consegna Da Affidamento',
      yAxisValue: 'Numero',
      xSeriesFields: ['Giorni consegna da affidamento'],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: false,
      dateField: 'Data Affidamento'
    }
  },
  {
    index: 1,
    pages: ['local', 'regional', 'national', 'general'],
    category: 'Tempi borderò da bolla',
    columns: 6,
    config: {
      xAxisField: 'Giorni Bordero Da Bolla',
      yAxisValue: 'Numero',
      xSeriesFields: ['Giorni bordero da bolla'],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: false,
      dateField: 'Data Bolla'
    }
  },
  {
    index: 2,
    pages: ['local', 'regional', 'national', 'general'],
    category: '% Senza POD / Data Consegna',
    columns: 12,
    config: {
      xAxisField: 'Data Consegna',
      yAxisValue: 'Percentuale Relativa',
      xSeriesFields: ['Spedizioni senza POD', 'Spedizioni con POD'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Consegna'
    }
  },
  {
    index: 3,
    pages: ['national', 'general'],
    category: 'Affidate / Data bolla',
    columns: 12,
    config: {
      xAxisField: 'Data Bolla',
      yAxisValue: 'Numero',
      xSeriesFields: ['Spedizioni affidate', 'Spedizioni non affidate'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: true,
      dateField: 'Data Bolla'
    }
  },
  {
    index: 4,
    pages: ['local', 'regional', 'national', 'general'],
    category: '% Spedizioni esitate su affidate',
    columns: 12,
    config: {
      xAxisField: 'Corriere',
      yAxisValue: 'Percentuale Relativa',
      xSeriesFields: ['Spedizioni consegnate su affidate', 'Spedizioni non consegnate su affidate'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      horizontalBars: true,
      dateField: 'Data Affidamento'
    }
  },
  {
    index: 5,
    pages: ['local', 'regional', 'national', 'general'],
    category: 'Spedizioni / Giorni Ritardo su tassativa',
    columns: 6,
    config: {
      xAxisField: 'Giorni Ritardo Consegna Su Tassativa',
      yAxisValue: 'Numero',
      xSeriesFields: ['Giorni ritardo su tassativa'],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: true,
      dateField: 'Data Tassativa'
    }
  },
  {
    index: 6,
    pages: ['local', 'regional', 'national', 'general'],
    category: '% Tassative in ritardo / Data tassativa',
    columns: 6,
    config: {
      xAxisField: 'Data Tassativa',
      yAxisValue: 'Percentuale Relativa',
      xSeriesFields: ['Spedizioni in ritardo su tassativa', 'Spedizioni non in ritardo su tassativa'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Tassativa'
    }
  },
  {
    index: 7,
    pages: ['local', 'regional', 'national', 'general'],
    category: 'Spedizioni con merce in ritardo (Data Bolla) / Data tassativa',
    columns: 6,
    config: {
      xAxisField: 'Data Tassativa',
      yAxisValue: 'Numero',
      xSeriesFields: ['Spedizioni con merce in ritardo', 'Spedizioni con merce non in ritardo'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Tassativa'
    }
  },
  {
    index: 8,
    pages: ['local', 'regional', 'national', 'general'],
    category: 'Spedizioni / Giorni Ritardo su prenotazione',
    columns: 6,
    config: {
      xAxisField: 'Giorni Ritardo Consegna Su Prenotata',
      yAxisValue: 'Numero',
      xSeriesFields: ['Giorni ritardo su prenotata'],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: true,
      dateField: 'Data Prenotata'
    }
  },
  {
    index: 9,
    pages: ['local', 'regional', 'national', 'general'],
    category: ' % Prenotate in ritardo / Data prenotata',
    columns: 6,
    config: {
      xAxisField: 'Data Prenotata',
      yAxisValue: 'Percentuale Relativa',
      xSeriesFields: ['Spedizioni in ritardo su prenotata', 'Spedizioni non in ritardo su prenotata'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Tassativa'
    }
  },
  {
    index: 10,
    pages: ['local', 'regional'],
    category: 'Numero spedizioni / Tempi di consegna / Provincia Roma – Altre province Lazio',
    columns: 6,
    config: {
      xAxisField: 'Tempi di consegna',
      yAxisValue: 'Numero',
      xSeriesFields: ['Provincia Roma', 'Altre province Lazio', 'Altre province'],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: true,
      dateField: 'Data Consegna'
    }
  },
  {
    index: 11,
    pages: ['local', 'regional'],
    category: '% Spedizioni consegnate / Tempi di consegna provincia di Roma',
    columns: 6,
    config: {
      xAxisField: 'Tempi di consegna Roma',
      yAxisValue: 'Percentuale Totale',
      xSeriesFields: ['Spedizioni consegnate su totale'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Consegna'
    }
  },
  {
    index: 12,
    pages: ['local', 'regional'],
    category: '% Spedizioni consegnate / Tempi di consegna altre province Lazio',
    columns: 6,
    config: {
      xAxisField: 'Tempi di consegna Lazio',
      yAxisValue: 'Percentuale Totale',
      xSeriesFields: ['Spedizioni consegnate su totale'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Consegna'
    }
  },
  {
    index: 13,
    pages: ['national', 'general'],
    category: '% Spedizioni consegnate / Tempi di consegna',
    columns: 6,
    config: {
      xAxisField: 'Data Consegna',
      yAxisValue: 'Percentuale Totale',
      xSeriesFields: ['Spedizioni consegnate su totale'],
      type: 'bar',
      stacked: true,
      height: 500,
      showPercent: false,
      dateField: 'Data Consegna'
    }
  },
  {
    index: 14,
    pages: ['local', 'regional', 'national', 'general'],
    category: 'Smarrite trovate - non trovate / Data Apert. Prat. Smarr.',
    columns: 6,
    config: {
      xAxisField: 'Data Apertura Pratica Smarrimento',
      yAxisValue: 'Numero',
      xSeriesFields: [
        'Spedizioni smarrite, in attesa di ritrovamento',
        'Spedizioni smarrite, ritrovate',
        'Spedizioni smarrite, non ritrovate'
      ],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: false,
      dateField: 'Data Apertura Pratica Smarrimento'
    }
  },
  {
    index: 15,
    pages: ['national', 'general'],
    category: 'Numero spedizioni / Tempi di consegna / Corriere',
    columns: 12,
    config: {
      xAxisField: 'Corriere',
      yAxisValue: 'Numero',
      xSeriesFields: ['Spedizioni consegnate 24', 'Spedizioni consegnate 48', 'Spedizioni consegnate 72', 'Spedizioni consegnate 72+'],
      type: 'bar',
      stacked: false,
      height: 500,
      showPercent: false,
      dateField: 'Data Consegna',
      horizontalBars: true
    }
  }
];

export const dashboard_grid_columns = [
  { headerName: 'Spedizione', field: 'numeroSpedizione' },
  { headerName: 'Data Bolla', field: 'dataBolla', cellRenderer: dateColumnRenderer },
  { headerName: 'Filiale', field: 'clienteFiliale' },
  { headerName: 'Cliente Codice', field: 'clienteCodice' },
  { headerName: 'Cliente Rag. Soc.', field: 'clienteRagioneSociale' },
  { headerName: 'Contratto', field: 'clienteContratto' },
  { headerName: 'Contratto Descrizione', field: 'clienteContrattoDescrizione' },
  { headerName: 'Corriere Codice', field: 'corriereCodice' },
  { headerName: 'Corriere Tipo', field: 'corriereTipo' },
  { headerName: 'Corriere Tipo Descrizione', field: 'corriereTipoDescrizione' },
  { headerName: 'Corriere Altro TipoCodice', field: 'corriereAltroTipoCodice' },
  { headerName: 'Corriere Altro Tipo Descrizione', field: 'corriereAltroTipoDescrizione' },
  { headerName: 'Corriere Ragione Sociale', field: 'corriereRagioneSociale' },
  { headerName: 'Cliente Localita', field: 'clienteLocalita' },
  { headerName: 'Cliente Provincia', field: 'clienteProvincia' },
  { headerName: 'Cliente CAP', field: 'clienteCAP' },
  { headerName: 'Cliente Regione', field: 'clienteRegione' },
  { headerName: 'Data Bordero', field: 'dataBordero', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Affidamento', field: 'dataAffidamento', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Tassativa', field: 'dataTassativa', cellRenderer: dateColumnRenderer },
  { headerName: 'Data ConsegnaPrenotata', field: 'dataConsegnaPrenotata', cellRenderer: dateColumnRenderer },
  { headerName: 'Data ConsegnaEffettiva', field: 'dataConsegnaEffettiva', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Ricezione', field: 'dataRicezione', cellRenderer: dateColumnRenderer },
  { headerName: 'Pod', field: 'pod', cellRenderer: booleanRenderer },
  { headerName: 'Bordero', field: 'bordero', cellRenderer: booleanRenderer },
  { headerName: 'Data Apertura Pratica Smarrimento', field: 'dataAperturaPraticaSmarrimento', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Chiusura Pratica Smarrimento', field: 'dataChiusuraPraticaSmarrimento', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Apertura Pratica SmarrimentoDef', field: 'dataAperturaPraticaSmarrimentoDef', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Apertura Pratica Danni', field: 'dataAperturaPraticaDanni', cellRenderer: dateColumnRenderer },
  { headerName: 'Data Chiusura Pratica Danni', field: 'dataChiusuraPraticaDanni', cellRenderer: dateColumnRenderer },
  { headerName: 'Merce Disguidata', field: 'merceDisguidata', cellRenderer: booleanRenderer },
  { headerName: 'Merce Smarrita', field: 'merceSmarrita', cellRenderer: booleanRenderer },
  { headerName: 'Note', field: 'note' }
];

const initialState = {};

const initRequestFilter = (filter) => {
  return {
    ...filter,
    dateField: getSingleSelectionString(filter.dateField) ?? INIT_DATE_VALUE,
    beginDate: filter.beginDate.format(longDateTimeFormat),
    endDate: filter.endDate.format(longDateTimeFormat),
    branch: getMultipleSelectionString(filter.branch) ?? ALL_VALUE,
    carrier: getMultipleSelectionString(filter.carrier) ?? ALL_VALUE,
    customer: getMultipleSelectionString(filter.customer) ?? ALL_VALUE,
    district: getMultipleSelectionString(filter.district) ?? ALL_VALUE,
    contract: getMultipleSelectionString(filter.contract) ?? ALL_VALUE,
    carrierType: getMultipleSelectionString(filter.carrierType) ?? ALL_VALUE,
    region: getMultipleSelectionString(filter.region) ?? ALL_VALUE
  };
};

export const getAllDataRows = createAsyncThunk('Dashboard/GetAllDataRows', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      ...payload.config,
      xAxisField: 'Tutti',
      xValue: ''
    };
    const res = await DoWebApiPostCall('GetChartDataRows', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getAllDataCSV = createAsyncThunk('Dashboard/GetAllDataCSV', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      xAxisField: 'Tutti',
      xSeriesFields: ['TUTTI'],
      xValue: ''
    };
    const res = await DoWebApiPostCall('GetChartCSV', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getChartDataRows = createAsyncThunk('Dashboard/GetChartDataRows', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      ...payload.config,
      xValue: ''
    };
    const res = await DoWebApiPostCall('GetChartDataRows', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getChartData = createAsyncThunk('Dashboard/GetChartData', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      ...payload.chart.config,
      xValue: ''
    };
    const res = await DoWebApiPostCall('GetChartData', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getChartDataCSV = createAsyncThunk('Dashboard/GetChartDataCSV', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      ...payload.config,
      xSeriesFields: payload.serie,
      xValue: ''
    };
    const res = await DoWebApiPostCall('GetChartCSV', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getChartColumnData = createAsyncThunk('Dashboard/GetChartColumnData', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      ...payload.config,
      xSeriesFields: payload.serie,
      xValue: payload.xValue
    };
    const res = await DoWebApiPostCall('GetChartColumnData', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getChartColumnCSV = createAsyncThunk('Dashboard/GetChartColumnCSV', async (payload, { rejectWithValue }) => {
  try {
    const filter = initRequestFilter(payload.filter);
    const body = {
      ...filter,
      ...payload.config,
      xSeriesFields: payload.serie,
      xValue: payload.xValue
    };
    const res = await DoWebApiPostCall('GetChartColumnCSV', body, payload.dispatch, true);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDataRows.pending, () => {});
    builder.addCase(getAllDataRows.fulfilled, (state) => {
      return { ...state };
    });
    builder.addCase(getAllDataCSV.pending, () => {});
    builder.addCase(getAllDataCSV.fulfilled, (state) => {
      return { ...state };
    });
    builder.addCase(getChartData.pending, () => {
      // let chart. = [...state.charts];
      // const index = meta.arg.chart.index;
      // chart.[index].data = LOADING;
      // state.charts = chart.;
    });
    builder.addCase(getChartData.fulfilled, () => {
      // let chart. = [...state.charts];
      // const index = meta.arg.chart.index;
      // chart.[index].data = payload.result.responseData;
      // state.charts = chart.;
    });
    builder.addCase(getChartData.rejected, () => {});
    builder.addCase(getChartDataRows.pending, () => {});
    builder.addCase(getChartDataRows.fulfilled, (state) => {
      return { ...state };
    });
    builder.addCase(getChartDataCSV.pending, () => {});
    builder.addCase(getChartDataCSV.fulfilled, (state) => {
      return { ...state };
    });
    builder.addCase(getChartColumnData.pending, () => {});
    builder.addCase(getChartColumnData.fulfilled, (state) => {
      return { ...state };
    });
    builder.addCase(getChartColumnCSV.pending, () => {});
    builder.addCase(getChartColumnCSV.fulfilled, (state) => {
      return { ...state };
    });
  }
});

export default dashboard.reducer;
