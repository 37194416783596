import moment from 'moment/moment';
import { createSlice } from '@reduxjs/toolkit';
import { ALL_VALUE, MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD } from '../../utils/common';

export const filterInitItem = {};
filterInitItem[MULTISELECT_ID_FIELD] = 0;
filterInitItem[MULTISELECT_LABEL_FIELD] = ALL_VALUE;

export const initFilter = {
  dateField: null,
  shipment: ALL_VALUE,
  beginDate: moment().clone().startOf('day'),
  endDate: moment().clone().startOf('day'),
  branch: [filterInitItem],
  carrier: [filterInitItem],
  carrierType: [filterInitItem],
  contract: [filterInitItem],
  customer: [filterInitItem],
  district: [filterInitItem],
  region: [filterInitItem],
  zone: '',
  branchRule: 1,
  carrierRule: 1,
  carrierTypeRule: 1,
  customerRule: 1,
  contractRule: 1,
  districtRule: 1,
  regionRule: 1
};

export const defaultFilterOptions = {
  hasDateFields: true,
  hasShipment: true,
  hasBranch: true,
  hasCarrier: true,
  hasCarrierType: true,
  hasContract: true,
  hasCustomer: true,
  hasDistrict: true,
  hasRegion: true,
  hasDownload: true
};

const initialState = {};

const filter = createSlice({
  name: 'filter',
  initialState,
  reducers: {},
  extraReducers: () => {}
});

export default filter.reducer;
