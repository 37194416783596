import { cloneDeep } from 'lodash';
import moment from 'moment';
import React from 'react';
import { TextGridPopover } from '../components/grid/TextPopover';
import { StatusBadge } from '../components/badges/StatusBadge';

export const intDateFormat = 'YYYYMMDD';
export const intDateTimeFormat = 'YYYYMMDDHHmm';
export const longDateTimeFormat = 'YYYY-MM-DDT00:00:00.000Z';
export const nullDateTime = '01/01/0001 00:00:00';
export const nullDate = '01/01/0001';
export const maxDate = '9999-12-31T23:59:59';
export const dateParseformat = 'YYYY-MM-DDTHH:mm:ss';
export const shortDateformat = 'DD/MM/YYYY';
export const emptyCompleteName = 'NON IMPOSTATO';
export const MULTISELECT_ID_FIELD = 'id';
export const MULTISELECT_LABEL_FIELD = 'label';
export const INIT_DATE_VALUE = 'Data Affidamento';
export const ALL_VALUE = 'TUTTI';
export const LOADING = 'loading';
export const ERROR = 'error';
export const ADMIN_PROFILE = 'admin';

export const GUEST_PROFILE = 'guest';

export const JSONClone = (obj) => {
  return cloneDeep(obj); //shallow copy
};

export const getRandomArbitrary = (min, max) => {
  return parseInt(Math.random() * (max - min)) + min;
};

export const firstLetter = (word) => {
  return word === '' ? word : Array.from(word)[0];
};

export const emptyJSON = {};

export const JSONIsEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const floatToString = (value) => {
  return parseFloat(value).toFixed(2).toString().replace('.', ',') ?? '0.00';
};

export const parseMoney = (value) => {
  if (!value) return null;
  value = value.toString().replace(',', '.');
  return parseFloat(parseFloat(value).toFixed(2));
};

export const floatToMoneyString = (value) => {
  return '€ ' + parseFloat(value).toFixed(2).toString().replace('.', ',') ?? '0.00';
};

export const moneyStringToFloat = (value) => {
  return parseFloat(value.toString().replace(',', '.')) ?? 0.0;
};

export const moneyColumnRenderer = (props) => {
  return '€ ' + floatToString(props.value);
};

export const floatColumnRenderer = (props) => {
  return floatToString(props.value);
};

export const booleanRenderer = (props) => {
  if ((props.value ?? false) === true) {
    return 'SI';
  } else {
    return 'NO';
  }
};

export const activeRendererWithBadge = (props) => {
  if ((props.value ?? '').toLowerCase() === 'attivo') {
    return <StatusBadge label={'SI'} color={'success'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  } else {
    return <StatusBadge label={'NO'} color={'error'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  }
};

export const logingStatusRenderer = (props) => {
  const status = props.node.data.mSessionStatus ?? '';
  const color = status.toUpperCase() === 'CONNESSO' ? 'success' : 'error';
  return <StatusBadge label={status} color={color} fullWidth={false} minWidth={130} width={130} size={'small'} />;
};

export const booleanRendererWithBadge = (props) => {
  if ((props.value ?? false) === true) {
    return <StatusBadge label={'SI'} color={'success'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  } else {
    return <StatusBadge label={'NO'} color={'error'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  }
};

export const booleanMatcher = ({ filterOption, value, filterText }) => {
  const textValue = value === 'true' ? 'SI' : 'NO';
  const filterTextToCompare = filterText.toUpperCase();
  if (filterText == null) {
    return false;
  }
  let index;
  switch (filterOption) {
    case 'contains':
      return textValue.indexOf(filterTextToCompare) >= 0;
    case 'notContains':
      return textValue.indexOf(filterTextToCompare) < 0;
    case 'equals':
      return textValue === filterTextToCompare;
    case 'notEqual':
      return textValue !== filterTextToCompare;
    case 'startsWith':
      return textValue.indexOf(filterTextToCompare) === 0;
    case 'endsWith':
      index = textValue.lastIndexOf(filterTextToCompare);
      return index >= 0 && index === textValue.length - filterTextToCompare.length;
    default:
      // should never happen
      console.warn('invalid filter type');
      return false;
  }
};

export const nameWithBadgeRenderer = (name, lastname, color) => {
  const label = getCompleteShortName(name, lastname);
  return <StatusBadge label={label} color={color ?? 'info'} fullWidth={true} minWidth={45} size={'small'} />;
};

export const textWithBadgeRenderer = (text, color) => {
  return <StatusBadge label={text} color={color ?? 'info'} fullWidth={true} minWidth={45} size={'small'} />;
};

export const dateColumnRenderer = (props) => {
  return moment(props.value).format('DD/MM/YYYY');
};

export const dateTimeColumnRenderer = (props) => {
  const val = moment(props.value).format('DD/MM/YYYY HH:mm:ss');
  return val !== nullDateTime ? val : '';
};

export const dateTimeShortColumnRenderer = (props) => {
  const val = moment(props.value).format('DD/MM/YYYY HH:mm');
  return val !== nullDateTime ? val : '';
};

export const timeColumnRenderer = (props) => {
  const val = moment(props.value).format('HH:mm:ss');
  return val !== nullDateTime ? val : '';
};

export const timeUtcColumnRenderer = (props) => {
  const val = moment(props.value).utc().format('HH:mm:ss');
  return val !== nullDateTime ? val : '';
};

export const weekdayRenderer = (props) => {
  const val = (props.value ?? '').toString();
  const foundElem = daysWithName.find((item) => item.mDayID.toString() === val);
  return (foundElem ?? {}).mDayName ?? '-';
};

export const formatNumber = (number) => {
  if ((number ?? 0) === 0) return '0,00';
  const num = number ?? 0;
  let ret = num.toFixed(2).toString();
  ret = ret.replace('.', ',');
  ret = ret.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return ret;
};

export const formatNumberDouble = (number) => {
  if ((number ?? 0) === 0) return '0,0000';
  let ret = (number ?? 0).toFixed(4).toString();
  ret = ret.replace('.', ',');
  ret = ret.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return ret;
};

export const dateFormatter = (params) => {
  if (!params) return '';
  if (!params.value) return '';
  // let dt = params.value.toString().replace("T00:00:00", "");
  // return moment(dt, "YYYY-MM-DD").format("DD/MM/YYYY");
  return moment(params.value).format('DD/MM/YYYY');
};

export const timeFormatter = (params) => {
  if (!params) return '';
  if (!params.value) return '';
  return moment(params.value).format('HH:mm');
};

export const textPopoverRenderer = (params) => {
  return <TextGridPopover extendedText={params.value} />;
};

export const defaultColDef = {
  sortable: true,
  editable: false,
  resizable: false,
  wrapText: false,
  autoHeight: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true
};

export const columnTypes = {
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flex3: { flex: 3 },
  flex4: { flex: 4 },
  statusColumn: { sortable: false, filter: '', suppressSizeToFit: true, flex: 1 },
  toolBar: { sortable: false, filter: '', suppressSizeToFit: false, width: 200 },
  toolButton: { sortable: false, filter: '', suppressSizeToFit: false, width: 100 },
  noFilter: { sortable: true, filter: '', suppressSizeToFit: false, autoHeight: true }
};

export const getCompleteName = (name, lastname) => {
  name = name ?? '';
  lastname = lastname ?? '';
  if (name === '' && lastname === '') {
    return emptyCompleteName;
  }
  return lastname.toUpperCase() + ' ' + name.toUpperCase();
};

export const getCompleteShortName = (name, lastname) => {
  name = name ?? '';
  lastname = lastname ?? '';
  if (name === '' && lastname === '') {
    return emptyCompleteName;
  }
  return lastname.toUpperCase() + ' ' + name.toUpperCase().charAt(0) + '.';
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const isDataNull = (arrData) => {
  return arrData === null;
};

export const isDataEmpty = (arrData) => {
  return (arrData ?? []).length === 0;
};

export const isDataLoading = (arrData) => {
  return arrData === LOADING;
};

export const isDataError = (arrData) => {
  return arrData === ERROR;
};

export const dateIsNull = (pDate) => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  if (!pDate) return true;
  return pDate ? moment(pDate).format(format) === nullDate : true;
};

export const normalizeDateTime = (pDate) => {
  return moment(pDate);
};

export const daysWithName = [
  { mDayID: 1, mDayName: 'LUNEDÌ' },
  { mDayID: 2, mDayName: 'MARTEDÌ' },
  { mDayID: 3, mDayName: 'MERCOLEDÌ' },
  { mDayID: 4, mDayName: 'GIOVEDÌ' },
  { mDayID: 5, mDayName: 'VENERDÌ' },
  { mDayID: 6, mDayName: 'SABATO' },
  { mDayID: 7, mDayName: 'DOMENICA' }
];

export const downloadCsvListOfByte = (arrayBuffer, fileName) => {
  const url = window.URL.createObjectURL(new Blob([arrayBuffer], { type: 'text/csv' }));
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  a.setAttribute('target', '_blank');
  a.setAttribute('rel', 'noreferrer');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getSelectOptions = (elements, objectField, idField, labelField) => {
  const options = [];
  let el;

  if (idField === '' && labelField === '') {
    elements.map((data, index) => {
      el = {};
      el.label = data;
      el[idField] = index;
      options.push(el);
      return null;
    });

    return options;
  }
  if (idField !== '' && labelField === '') {
    elements.map((data) => {
      el = {};
      el.label = data;
      el[idField] = data[idField];
      options.push(el);
      return null;
    });
    return options;
  }
  if (idField === '' && labelField !== '') {
    elements.map((data, index) => {
      el = {};
      el.label = data[labelField];
      el[idField] = index;
      options.push(el);
      return null;
    });
    return options;
  }
  if (idField !== '' && labelField !== '' && objectField === '') {
    elements.map((data) => {
      el = {};
      el.label = data[labelField];
      el[idField] = data[idField];
      options.push(el);
      return null;
    });
    return options;
  }
  if (idField !== '' && labelField !== '' && objectField !== '') {
    elements.map((data) => {
      el = {};
      el.label = data[objectField][labelField];
      el[idField] = data[objectField][idField];
      options.push(el);
      return null;
    });
    return options;
  }
};

export const getMultipleSelectionString = (items) => {
  const retString = items.map((item) => item[MULTISELECT_LABEL_FIELD]).join('|');
  return retString === '' ? ALL_VALUE : retString;
};

export const getSingleSelectionString = (item) => {
  const retString = item ? item[MULTISELECT_LABEL_FIELD] : '';
  return retString === '' ? ALL_VALUE : retString;
};
