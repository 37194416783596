import { Autocomplete, Chip, TextField } from '@mui/material';
import React from 'react';
import { filterInitItem } from '../../store/reducers/filter';

export const InputAutoComplete = ({ multiple, label, options, value, setValue, fullWidth, disabled, ...props }) => {
  const initialOptions = options ?? [];
  const isDisabled = disabled ?? false;

  return (
    <Autocomplete
      multiple={multiple ?? true}
      id={props.id + '-input'}
      key={props.key + '-input'}
      label={label}
      options={initialOptions}
      isOptionEqualToValue={(option, value) => (option ?? filterInitItem).id === value.id}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(event, item) => {
        setValue(item);
      }}
      disabled={isDisabled}
      renderInput={(params) => <TextField label={label} {...params} />}
      fullWidth={fullWidth ?? false}
      renderTags={(list) => {
        let displayList = list.map((item) => item.label).join('');
        return list.length > 1 ? <Chip label={list.length + ' elementi selezionati'} /> : <Chip label={displayList} />;
      }}
      {...props}
    />
  );
};
