import { createSlice } from '@reduxjs/toolkit';
import config from '../../config';
import { navItems } from '../../routes/LoggedNav';

// initial state
const initialState = {
  openItem: [config.defaultMenu],
  openTitle: '',
  openDescription: '',
  defaultId: 'home',
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
      let idToFind = action.payload.openItem[0];
      let foundTitle = '';
      let foundDescription = '';
      navItems.map((group) => {
        if (group.id === idToFind) {
          foundTitle = group.title;
          foundDescription = group.description;
        } else if ((group.children ?? []).length > 0) {
          group.children.map((menu) => {
            if (menu.id === idToFind) {
              foundTitle = group.title + ' - ' + menu.title;
              foundDescription = menu.description;
            }
          });
        }
      });
      state.openTitle = foundTitle;
      state.openDescription = foundDescription;
      state.drawerOpen = false;
    },
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    }
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer } = menu.actions;
