import { Outlet } from 'react-router-dom';
import { AlertsTree } from '../../components/alerts/AlertsTree';

// ==============================|| MINIMAL LAYOUT ||============================== //

const NotLoggedLayout = () => {
  return (
    <>
      <Outlet />
      <AlertsTree />
    </>
  );
};

export default NotLoggedLayout;
