import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import { removeCustomRequest, removeDataAlertRequest } from '../../store/reducers/errors';

export const CustomAlert = ({ item, handleHide, open, message, severity }) => {
  const dispatch = useDispatch();

  let suffix = '';

  if (item.apiURL && severity !== 'success') suffix += ' durante la chiamata ' + item.apiURL;

  const completeMessage = message + suffix;

  const onClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(removeCustomRequest({ ...item }));
    dispatch(removeDataAlertRequest({ ...item }));
    if (handleHide) handleHide();
  };

  const onAlertClose = () => {
    dispatch(removeCustomRequest({ ...item }));
    dispatch(removeDataAlertRequest({ ...item }));
    if (handleHide) handleHide();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      key={item.requestId}
    >
      <Tooltip title={completeMessage} arrow>
        <Alert variant="filled" severity={severity} onClose={onAlertClose}>
          {message}
        </Alert>
      </Tooltip>
    </Snackbar>
  );
};
