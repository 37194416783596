import { Box, CardContent, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../themes/Styles';
import { CircularWaiting } from '../waitings/CircularWaiting';
import * as ReactDOMServer from 'react-dom/server';
import { renderToString } from 'react-dom/server';
import { columnTypes, defaultColDef, downloadCsvListOfByte, formatNumber, isDataLoading, isDataNull } from '../../utils/common';
import { StyledChartHeader } from './SyledChartHeader';
import { useDispatch } from 'react-redux';
import {
  dashboard_grid_columns,
  getChartColumnCSV,
  getChartColumnData,
  getChartDataCSV,
  getChartDataRows
} from '../../store/reducers/dashboard';
import { CardModal } from '../card/CardModal';
import { AgGridReact } from 'ag-grid-react';
import { StyledChartBox } from './SyledChartBox';
import config from '../../config';
import { IconLibraryItem } from '../icons/IconLibraryItem';

const ShipmentsChart = ({ chart, filter }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();

  const chartData = chart.data ?? null;
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState('');
  const [selectedXValue, setSelectedXValue] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalSource, setModalSource] = useState('');

  const series = [];
  let categories = [];

  let csvFileName = `${chart.config.xAxisField}`;
  const bHasHorizontalBars = chart.config.horizontalBars ?? false;
  const isStacked = chart.config.stacked ?? false;
  let iHeight = chart.config.height ?? 0;
  let seriesCount = 0;
  const yAxisValue = chart.config.yAxisValue;
  const yAxisLabel = chart.config.yAxisValue ?? '';
  const xSeriesLabels = chart.config.xSeriesFields ?? '';
  const showPerc = chart.config.showPercent ?? false;

  const withPerc = yAxisValue === 'Percentuale Relativa' || yAxisValue === 'Percentuale Totale';

  const customColors = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.info.dark, theme.palette.info.light];
  const labelColor = '#08111F';

  if (!isDataNull(chartData) && !isDataLoading(chartData)) {
    seriesCount = chartData.xSerieNames.length;

    categories = chartData.xAxisValues;

    for (let indexSeries = 0; indexSeries < seriesCount; indexSeries += 1) {
      if (yAxisValue === 'Numero') {
        series.push({ name: xSeriesLabels[indexSeries], data: chartData.xSerieValues[indexSeries] });
      } else if (yAxisValue === 'Percentuale Totale') {
        series.push({ name: xSeriesLabels[indexSeries], data: chartData.xSeriePercValues[indexSeries] });
      } else if (yAxisValue === 'Percentuale Relativa') {
        series.push({ name: xSeriesLabels[indexSeries], data: chartData.xSeriePercRelValues[indexSeries] });
      }
      // values.push({ yAxisValues, yPercValues, yRelPercValues });
    }

    const iRows = categories.length;
    const stackedHeight = isStacked ? Math.max(25 * iRows, iHeight) : Math.max(20 * iRows * seriesCount, iHeight);
    iHeight = bHasHorizontalBars ? stackedHeight : iHeight;
    csvFileName += xSeriesLabels.join('_');
  }

  const getTitleFormatter = (seriesName) => {
    const element = <div style={{ display: 'flex', 'align-items': 'center', padding: '0px', 'margin-left': '5px' }}>{seriesName}</div>;
    return ReactDOMServer.renderToString(element);
  };

  const getToolTipFormatter = (value, { seriesIndex, dataPointIndex }) => {
    let element;

    if (showPerc) {
      element = (
        <div>
          <div>
            <span>Valore: </span>
            <span>{chartData.xSerieValues[seriesIndex][dataPointIndex]}</span>
          </div>
          <div>
            <span>% relativa: </span>
            <span>{chartData.xSeriePercRelValues[seriesIndex][dataPointIndex].toFixed(2)}</span>
          </div>
          <div>
            <span>% su totale: </span>
            <span>{chartData.xSeriePercValues[seriesIndex][dataPointIndex].toFixed(2)}</span>
          </div>
        </div>
      );
    } else {
      element = (
        <div>
          <div>
            <span>Valore: </span>
            <span>{chartData.xSerieValues[seriesIndex][dataPointIndex]}</span>
          </div>
        </div>
      );
    }
    return ReactDOMServer.renderToString(element);
  };

  const chartOnClick = (config) => {
    const seriesIndex = config.seriesIndex;
    const dataPointIndex = config.dataPointIndex;

    if (seriesIndex < 0) return;
    if ((categories.length ?? []) === 0) return;

    const seriesName = series[seriesIndex].name;
    const xValue = categories[dataPointIndex];

    const payload = {
      config: chart.config,
      serie: [seriesName],
      xValue: xValue,
      filter: filter,
      dispatch: dispatch
    };
    setModalOpen(true);
    setIsDownloading(true);
    dispatch(getChartColumnData(payload)).then((res) => {
      if (!getChartColumnData.fulfilled.match(res)) return null;
      setIsDownloading(false);
      setModalSource('downloadColumnCSV');
      setSelectedRows(res.payload.result.responseData);
      setSelectedSeries(seriesName);
      setSelectedXValue(xValue);
    });
  };

  const allDownloadClick = () => {
    const payload = {
      config: chart.config,
      // xValue: xValue,
      filter: filter,
      dispatch: dispatch
    };
    setModalOpen(true);
    setIsDownloading(true);
    dispatch(getChartDataRows(payload)).then((res) => {
      if (!getChartDataRows.fulfilled.match(res)) return null;
      setIsDownloading(false);
      setModalSource('downloadChartCSV');
      setSelectedRows(res.payload.result.responseData);
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [columns] = useState([...dashboard_grid_columns]);

  const downloadColumnCSV = () => {
    const payload = {
      config: chart.config,
      serie: [selectedSeries],
      xValue: selectedXValue,
      filter: filter,
      dispatch: dispatch
    };
    setIsDownloading(true);
    dispatch(getChartColumnCSV(payload)).then((res) => {
      if (!getChartColumnCSV.fulfilled.match(res)) return null;
      const csv = res.payload.result.responseData ?? '';
      const extension = 'csv';
      const xSeriesFields = chart.config.xSeriesFields.join('_');
      const fileName = `${chart.config.xAxisField}_${xSeriesFields}.${extension}`;
      downloadCsvListOfByte(csv, fileName);
      setIsDownloading(false);
    });
  };

  const downloadChartCSV = () => {
    const payload = {
      config: chart.config,
      serie: [selectedSeries],
      // xValue: selectedXValue,
      filter: filter,
      dispatch: dispatch
    };
    setIsDownloading(true);
    dispatch(getChartDataCSV(payload)).then((res) => {
      if (!getChartDataCSV.fulfilled.match(res)) return null;
      const csv = res.payload.result.responseData ?? '';
      const extension = 'csv';
      const xSeriesFields = chart.config.xSeriesFields.join('_');
      const fileName = `${chart.config.xAxisField}_${xSeriesFields}.${extension}`;
      downloadCsvListOfByte(csv, fileName);
      setIsDownloading(false);
    });
  };

  const download = () => {
    if (modalSource === 'downloadColumnCSV') {
      downloadColumnCSV();
    } else if (modalSource === 'downloadChartCSV') {
      downloadChartCSV();
    }
  };

  const resetIcon = renderToString(<IconLibraryItem name={'reset'} iconSize={'small'}/>, {});

  let downloadButtons = [];
  const downloadIcon = renderToString(<div style={{ margin: 1, height: 25, width: 25}}><IconLibraryItem name={'download'} iconSize={'small'} /></div>, {});
  downloadButtons.push({
    icon: downloadIcon ?? '<></>',
    title: 'Apri dettaglio',
    click: () => {
      allDownloadClick();
    }
  });

  const apexChartOptions = {
    chart: {
      type: 'bar',
      selection: { enabled: true },
      toolbar: {
        show: true,
        offsetX: -20,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | resetIcon ?? '<></>',
          customIcons: [...downloadButtons]
        },
        export: {
          csv: {
            filename: csvFileName,
            columnDelimiter: ';',
            headerCategory: 'X',
            headerValue: 'Y',
            dateFormatter(timestamp) {
              return new Date(timestamp).endDateString();
            }
          }
        },
        autoSelected: 'zoom'
      },
      events: {
        click: (event, chartContext, config) => {
          chartOnClick(config);
        }
      },
      stacked: chart.config.stacked,
      fontSize: theme.typography.fontSize,
      fontWeight: 'bold',
      fontFamily: config.fontFamily
    },
    plotOptions: {
      bar: {
        horizontal: bHasHorizontalBars,
        borderRadius: 0,
        columnWidth: '98%',
        barHeight: '70%',
        endingShape: 'flat',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        dataLabels: {
          position: 'top',
          style: {
            fontSize: '18px',
            fontWeight: 'bold',
            colors: [customColors[4]]
          },
          // maxItems: 10,
          hideOverflowingLabels: true,
          orientation: 'horizontal',
          total: {
            enabled: !withPerc,
            offsetX: bHasHorizontalBars ? 0 : 0,
            offsetY: bHasHorizontalBars ? 8 : 0,
            style: {
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: 600
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: withPerc,
      offsetY: 0,
      offsetX: 0,
      formatter: (y) => {
        // if (bHasHorizontalBars) return "";
        let ret = formatNumber(y);
        ret += withPerc ? ' %' : '';
        return ret;
      },
      background: {
        enabled: true,
        foreColor: customColors[0],
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: customColors[0]
      }
    },
    colors: customColors,
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'center'
    },
    stroke: {
      show: true
    },
    yaxis: {
      lines: {
        show: true
      },
      show: true,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        },
        maxWidth: 400,
        formatter: (y) => {
          if (bHasHorizontalBars) return y;
          if ((y ?? 0) === 0) return '';
          let ret = formatNumber(y);
          ret += withPerc ? ' %' : '';
          return ret;
        }
      },
      title: {
        text: bHasHorizontalBars ? chart.config.xAxisField : yAxisLabel,
        style: {
          fontSize: '12px',
          color: labelColor
        }
      }
    },
    xaxis: {
      show: true,
      categories,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      },
      title: {
        text: bHasHorizontalBars ? yAxisLabel : chart.config.xAxisField,
        style: {
          fontSize: '12px',
          color: labelColor
        }
      }
    },
    grid: {
      row: {
        colors: [theme.palette.grey['100'], theme.palette.grey['300']], // takes an array which will be repeated on columns
        opacity: 0.2
      }
    },
    tooltip: {
      y: {
        title: {
          formatter: getTitleFormatter
        },
        formatter: getToolTipFormatter
      }
    }
  };

  const rowsCount = chartData ? chartData.rowsCount ?? 0 : 0;

  let modalActions = [{ icon: 'download', tooltip: 'Scarica CSV', action: download }];

  return (
    <>
      <Box justifyContent={'center'} sx={loggedClasses.chartContainerStyle}>
        <StyledChartHeader>
          <Typography variant={'h5'}>{chart.category}</Typography>
          <Typography variant={'body2'}>
            Filtro: {chart.config.dateField} ( {rowsCount} sped.)
          </Typography>
        </StyledChartHeader>
        <StyledChartBox sx={{ minHeight: iHeight }}>
          {isDataLoading(chartData) ? (
            <Grid item xs={12}>
              <CircularWaiting />
            </Grid>
          ) : (
            <ReactApexChart options={apexChartOptions} series={series} type={chart.config.type} height={iHeight} />
          )}
        </StyledChartBox>
      </Box>
      <CardModal modalOpen={modalOpen} onClose={closeModal} title={'Dettaglio spedizioni'} size={'xl'} actions={modalActions}>
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={theme.spacing(1)} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            {isDownloading ? (
              <Grid item xs={12}>
                <CircularWaiting />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                    <AgGridReact
                      rowData={selectedRows}
                      defaultColDef={defaultColDef}
                      columnDefs={columns}
                      columnTypes={columnTypes}
                      suppressMovableColumns={true}
                      suppressCellFocus={true}
                      pagination={true}
                      rowSelection={'single'}
                      getRowHeight={() => gridRowHeight}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default ShipmentsChart;
