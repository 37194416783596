import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { columnTypes, defaultColDef, isDataError, isDataLoading, isDataNull } from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { CardModal } from '../../../components/card/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  createRegionalCarrier,
  deleteRegionalCarrier,
  fetchRegionalCarrier,
  fetchRegionalCarriers,
  updateRegionalCarrier
} from '../../../store/reducers/registries';
import { showConfirmDialog } from '../../../store/reducers/errors';
import { isAdminLogged } from '../../../store/reducers/auth';

const Carriers = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const carriers = useSelector((state) => state.registries.regionalCarriers);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(carriers)) return;
    if (isDataError(carriers)) return;
    const payload = { dispatch: dispatch };
    dispatch(fetchRegionalCarriers(payload)).then(() => {
      setDataLoaded(true);
    });
  }, [dispatch, dataLoaded, carriers]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    const recID = record.corriereID ?? -1;
    if (recID === 0) {
      dispatch(createRegionalCarrier(payload)).then(() => {
        setModalOpen(false);
        setDataLoaded(false);
      });
    } else {
      dispatch(updateRegionalCarrier(payload)).then(() => {
        setModalOpen(false);
        setDataLoaded(false);
      });
    }
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { corriereCodice: '' }, dispatch: dispatch };
    dispatch(fetchRegionalCarrier(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { corriereCodice: selectedRow.corriereCodice }, dispatch: dispatch };
      dispatch(fetchRegionalCarrier(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const confirm = {
        title: 'Cancellazione corriere',
        message: 'Attenzione! Questa azione comporterà la cancellazione del corriere. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { ...selectedRow }, dispatch: dispatch };
          dispatch(deleteRegionalCarrier(payload)).then(() => {
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  let cols = [
    { headerName: 'Codice', field: 'corriereCodice' },
    { headerName: 'Nome', field: 'corriereNome' }
  ];

  if (isAdminLogged()) {
    cols = [...cols, { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }];
  }

  let [columns] = useState(cols);

  if (isDataNull(carriers) || isDataLoading(carriers) || isDataError(carriers)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Corrieri Lazio</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            <AgGridReact
              rowData={carriers}
              defaultColDef={defaultColDef}
              columnDefs={columns}
              columnTypes={columnTypes}
              suppressMovableColumns={true}
              suppressCellFocus={true}
              pagination={true}
              rowSelection={'single'}
              getRowHeight={() => gridRowHeight}
            />
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio utente'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={theme.spacing(2)} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12} lg={4}>
              <FormTextBox
                label="Codice"
                control={control}
                record={record}
                register={register}
                field="corriereCodice"
                required={true}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="corriereNome"
                required={true}
                maxLength={100}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default Carriers;
