import { useMemo } from 'react';

// material-ui
import { createTheme } from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import config from '../config';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function PrimaryTheme() {
  const theme = Palette('light', 'default');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = Typography(config.fontFamily);

  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      palette: theme.palette,
      typography: themeTypography,
      customShadows: themeCustomShadows,
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536
        }
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      }
    }),
    [theme, themeTypography, themeCustomShadows]
  );

  const primaryTheme = createTheme(themeOptions);
  primaryTheme.components = componentsOverride(primaryTheme);

  return primaryTheme;
}
