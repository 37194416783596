import { lazy } from 'react';
import Loadable from 'components/Loadable';
import NotLoggedLayout from 'layout/NotLogged';

const AuthLogin = Loadable(lazy(() => import('pages/not-logged/Login')));

const NotLoggedRoutes = {
  path: '/',
  element: <NotLoggedLayout />,
  children: [
    {
      path: '/Login',
      element: <AuthLogin />
    }
  ]
};

export default NotLoggedRoutes;
