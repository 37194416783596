import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, styled, Typography } from '@mui/material';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import React from 'react';
import config from  '../../../../config';

// ==============================|| DRAWER HEADER ||============================== //

export const StyledAppTitle = ({ ...props }) => {
  const theme = useTheme();

  const StyledComponent = styled(Stack)({
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.contrastText
  });

  return <StyledComponent>{props.children}</StyledComponent>;
};

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <StyledAppTitle direction="column" spacing={1} justifyContent={"center"} alignItems="center">
        <Typography variant={"h5"} textAlign={"center"}>
          {config.appTitle}
        </Typography>
        <Typography variant={"h6"} textAlign={"center"}>
          {process.env.REACT_APP_VERSION}
        </Typography>
      </StyledAppTitle>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
