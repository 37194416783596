import axios from 'axios';
import { AXIOS_CONFIG_SESSION_KEY, setSessionValue } from 'utils/storage';
import { beginServiceCall, endServiceCall, handleErrorServiceCall } from '../store/reducers/errors';

const configAPIURL = process.env.REACT_APP_SERVICE_URI;
const apiURL = configAPIURL ? configAPIURL : '';

const axiosInstance = axios.create({
  baseURL: apiURL
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = { 'Content-Type': 'application/json' };
    setSessionValue(AXIOS_CONFIG_SESSION_KEY, config);
    return config;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const DoApiCall = async (pType, pUrl, pBody, dispatch, pShowOnlyError) => {
  let callResponse = new WebAPICallResponse();
  const beginPayload = {
    apiURL: pUrl,
    showOnlyError: pShowOnlyError
  };
  let res = null;

  if (!dispatch) {
    console.log('***************************************** DISPATCH NULL!!!! *****************************************');
    return res;
  }

  let request;

  try {
    const beginRes = await dispatch(beginServiceCall(beginPayload));
    request = beginRes.payload.result;
    switch (pType) {
      case 'post':
        res = await axiosInstance.post(pUrl, pBody);
        break;
      case 'get':
        res = await axiosInstance.get(pUrl);
        break;
    }
    res = res ?? {
      responseData: [],
      responseCode: 0,
      responseMessage: '',
      responseAnyError: false
    };
    if (res.data.responseData) {
      callResponse = {
        ...callResponse,
        ...res.data
      };
    } else {
      //il ritorno del csv non ha l'oggetto callResponse
      callResponse = {
        responseData: res.data,
        responseCode: 0,
        responseMessage: '',
        responseAnyError: false
      };
    }
  } catch (error) {
    callResponse.responseData = [];
    callResponse.responseAnyError = true;
    callResponse.responseApiKey = '';
    if (error.response) {
      callResponse.responseCode = parseInt(error.response.status);
      callResponse.responseMessage = error.response.statusText;
    } else if (error.request) {
      callResponse.responseCode = -100;
      callResponse.responseMessage = 'Nessuna risposta dal server.';
    } else {
      // Something happened in setting up the request that triggered an Error
      callResponse.responseCode = -200;
      callResponse.responseMessage = 'Errore di configurazione della chiamata.';
    }
  } finally {
    if (request) {
      const endPayload = {
        requestId: request.requestId,
        result: callResponse
      };

      const endRes = await dispatch(endServiceCall(endPayload));

      request = endRes.payload.result;
    } else {
      callResponse.responseData = [];
      callResponse.responseAnyError = true;
      callResponse.responseApiKey = '';
      callResponse.responseCode = -300;
      callResponse.responseMessage = 'Errore di inizializzazione della chiamata.';

      const endPayload = {
        requestId: 'error',
        result: callResponse
      };

      const endRes = await dispatch(handleErrorServiceCall(endPayload));

      request = endRes.payload.result;
    }
  }
  return request;
};

export const DoWebApiPostCall = async (pUrl, pBody, dispatch, pShowOnlyError) => {
  return await DoApiCall('post', pUrl, pBody, dispatch, pShowOnlyError);
};

export const DoWebApiGetCall = async (pUrl, dispatch, pShowOnlyError) => {
  return await DoApiCall('get', pUrl, {}, dispatch, pShowOnlyError);
};

class WebAPICallResponse {
  constructor() {
    this.responseData = [];
    this.responseCode = 0;
    this.responseMessage = '';
    this.responseAnyError = false;
    this.responseApiKey = false;
    this.responseDataPagesCount = 0;
    this.responseDataCurrentPage = 0;
    this.responseDataPageSize = 0;
    this.responseDataRowsCount = 0;
    this.responseAuthCode = '';
  }
}

export default axiosInstance;
