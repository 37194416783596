import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import { IconLibraryItem } from '../icons/IconLibraryItem';
import { ActionButton } from '../buttons/ActionButton';
import { loggedClassNames } from '../../themes/Styles';
import { hideConfirmDialog } from '../../store/reducers/errors';

export const CustomConfirmDialog = ({ item, open, title, message, handleCancel, handleConfirm }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const onConfirm = (event, reason) => {
    if (reason === 'clickaway') return;
    handleConfirm();
    dispatch(hideConfirmDialog({ ...item }));
  };

  const onCancel = (event, reason) => {
    if (reason === 'clickaway') return;
    handleCancel();
    dispatch(hideConfirmDialog({ ...item }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      key={item.requestId}
    >
      <DialogTitle id="alert-dialog-title" sx={loggedClasses.customConfirmDialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={loggedClasses.customConfirmDialogMessage}>
          <IconLibraryItem customSize={150} name={'question-mark'} />
          <Box>{message}</Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={loggedClasses.customConfirmDialogActions}>
        <ActionButton
          color={'primary'}
          variant="contained"
          action={onConfirm}
          size="medium"
          icon="check"
          iconSize="medium"
          tooltip="Conferma"
          label="Conferma"
          fullWidth
        />
        <ActionButton
          color={'secondary'}
          variant="contained"
          action={onCancel}
          size="medium"
          icon="cancel"
          iconSize="medium"
          tooltip="Annulla"
          label="Annulla"
          fullWidth
        />
      </DialogActions>
    </Dialog>
  );
};
