import { combineReducers } from 'redux';
import authReducer from './auth';
import dashboardReducer from './dashboard';
import errorsReducer from './errors';
import filterReducer from './filter';
import menuReducer from './menu';
import registriesReducer from './registries';

const reducers = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  errors: errorsReducer,
  filter: filterReducer,
  menu: menuReducer,
  registries: registriesReducer
});

export default reducers;
