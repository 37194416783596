import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiGetCall, DoWebApiPostCall } from '../../services/AxiosInstance';
import { ERROR, getSelectOptions, JSONClone, LOADING, MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD } from '../../utils/common';

const newCarrier = {
  corriereID: 0,
  corriereCodice: 'NEW',
  corriereNome: 'NUOVO CORRIERE'
};

const newUser = {
  userID: 0,
  username: 'nuovo',
  name: 'nome nuovo utente',
  password: ' '
};

// initial state
let initialState = {
  dateFields: {
    records: null,
    options: []
  },
  branches: {
    records: null,
    options: []
  },
  carriers: {
    records: null,
    options: []
  },
  carrierTypes: {
    records: null,
    options: []
  },
  customers: {
    records: null,
    options: []
  },
  contracts: {
    records: null,
    options: []
  },
  districts: {
    records: null,
    options: []
  },
  regions: {
    records: null,
    options: []
  },
  regionalCarriers: null,
  users: null
};

export const fetchBranches = createAsyncThunk('registries/fetchBranches', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetBranches', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchCarriers = createAsyncThunk('registries/fetchCarriers', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetCarriers', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchCarrierTypes = createAsyncThunk('registries/fetchCarrierTypes', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetCarrierTypes', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchCustomers = createAsyncThunk('registries/fetchCustomers', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetCustomers', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchContracts = createAsyncThunk('registries/fetchContracts', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetContracts', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchDateFields = createAsyncThunk('registries/fetchDateFields', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetDateFields', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchDistricts = createAsyncThunk('registries/fetchDistricts', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetDistricts', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchRegionalCarriers = createAsyncThunk('registries/fetchRegionalCarriers', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/CarriersLazio/GetList', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchRegionalCarrier = createAsyncThunk('registries/fetchRegionalCarrier', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.corriereCodice !== '') {
      const res = await DoWebApiPostCall('/CarriersLazio/Get', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newCarrier) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const createRegionalCarrier = createAsyncThunk('registries/createRegionalCarrier', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/CarriersLazio/Create', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateRegionalCarrier = createAsyncThunk('registries/updateRegionalCarrier', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/CarriersLazio/Update', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteRegionalCarrier = createAsyncThunk('registries/deleteRegionalCarrier', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/CarriersLazio/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchRegions = createAsyncThunk('registries/fetchRegions', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/GetRegions', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchUsers = createAsyncThunk('registries/fetchUsers', async (payload, { rejectWithValue }) => {
  try {
    const res = await DoWebApiGetCall('/Users/GetList', payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchUser = createAsyncThunk('registries/fetchUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    if (body.username !== '') {
      const res = await DoWebApiPostCall('/Users/Get', body, payload.dispatch, true);
      if (res.anyError) return rejectWithValue(res);
      if (!res.responseData) return rejectWithValue(res);
      if (res.responseData.length === 0) return rejectWithValue(res);
      return { result: res.responseData[0] };
    } else {
      return { result: JSONClone(newUser) };
    }
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const createUser = createAsyncThunk('registries/createUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/Create', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateUser = createAsyncThunk('registries/updateUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/Update', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const deleteUser = createAsyncThunk('registries/deleteUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/Delete', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const registriesSlice = createSlice({
  name: 'registries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBranches.pending, (state) => {
      state.branches = {
        records: LOADING,
        options: []
      };
    });
    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.branches = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.branches = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchBranches.rejected, (state) => {
      state.branches = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchCarriers.pending, (state) => {
      state.carriers = {
        records: LOADING,
        options: []
      };
    });
    builder.addCase(fetchCarriers.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.carriers = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.carriers = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchCarriers.rejected, (state) => {
      state.carriers = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchCarrierTypes.pending, (state) => {
      state.carrierTypes = LOADING;
    });
    builder.addCase(fetchCarrierTypes.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.carrierTypes = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.carrierTypes = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchCarrierTypes.rejected, (state) => {
      state.carrierTypes = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchCustomers.pending, (state) => {
      state.customers = LOADING;
    });
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.customers = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.customers = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchCustomers.rejected, (state) => {
      state.customers = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchContracts.pending, (state) => {
      state.contracts = LOADING;
    });
    builder.addCase(fetchContracts.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.contracts = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.contracts = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchContracts.rejected, (state) => {
      state.contracts = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchDateFields.pending, (state) => {
      state.dateFields = {
        records: LOADING,
        options: []
      };
    });
    builder.addCase(fetchDateFields.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.dateFields = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.dateFields = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchDateFields.rejected, (state) => {
      state.dateFields = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchDistricts.pending, (state) => {
      state.districts = LOADING;
    });
    builder.addCase(fetchDistricts.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value };
        });
        state.districts = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.districts = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchDistricts.rejected, (state) => {
      state.districts = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchRegionalCarriers.pending, (state) => {
      state.regionalCarriers = LOADING;
    });
    builder.addCase(fetchRegionalCarriers.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        state.regionalCarriers = res.responseData ?? [];
      } else {
        state.regionalCarriers = ERROR;
      }
    });
    builder.addCase(fetchRegionalCarriers.rejected, (state) => {
      state.regionalCarriers = ERROR;
    });
    builder.addCase(fetchRegions.pending, (state) => {
      state.regions = LOADING;
    });
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      const res = action.payload.result;
      if (!res.responseAnyError) {
        let recs = (res.responseData ?? []).map((value, index) => {
          return { id: index, label: value ?? '' };
        });
        state.regions = {
          records: res.responseData ?? [],
          options: getSelectOptions(recs, '', MULTISELECT_ID_FIELD, MULTISELECT_LABEL_FIELD)
        };
      } else {
        state.regions = {
          records: ERROR,
          options: []
        };
      }
    });
    builder.addCase(fetchRegions.rejected, (state) => {
      state.regions = {
        records: ERROR,
        options: []
      };
    });
    builder.addCase(fetchUsers.pending, (state) => {
      state.users = LOADING;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.users = res.responseData ?? [];
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.users = [];
    });
  }
});

// export const { setSessionExpired, setSessionRemainingTime } = authSlice.actions;
export default registriesSlice.reducer;
