import PropTypes from 'prop-types';

// material-ui
import { Button, Collapse, List, Typography, useTheme } from '@mui/material';

// project import
import NavItem from './NavItem';
import { IconLibraryItem } from '../../../../../components/icons/IconLibraryItem';
import { useState } from 'react';
import { ArrowDropDownTwoTone, ArrowDropUpTwoTone } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const MenuGroupButton = styled(Button)(({ theme }) => ({
  minHeight: '35px !important',
  maxHeight: '35px !important',
  width: '90%',
  // boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.light
  },
  marginTop: '10px',
  marginLeft: '10px',
  display: 'flex',
  justifyContent: 'flex-start'
}));

const MenuGroupToogleButton = styled(Button)(() => ({
  minHeight: '25px !important',
  maxHeight: '25px !important',
  minWidth: '25px',
  position: 'absolute',
  left: '80%'
}));

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);

  const { drawerOpen } = menu;

  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  if (!item.children || item.children.length === 0) return <></>;

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <>
            <MenuGroupButton
              variant={'outlined'}
              id={'menu-button-' + item.title}
              key={'menu-button-' + item.title}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              {<IconLibraryItem name={item.icon} iconSize={'small'} sx={{ mr: theme.spacing(1) }} />}
              <Typography color={theme.palette.secondary} variant={'h5'}>
                {item.title}
              </Typography>
              <MenuGroupToogleButton>
                {expanded ? <ArrowDropUpTwoTone size={'md'} /> : <ArrowDropDownTwoTone size={'md'} />}
              </MenuGroupToogleButton>
            </MenuGroupButton>
          </>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      <Collapse in={expanded}>{navCollapse}</Collapse>
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
