import { Stack, useTheme } from '@mui/material';
import React from 'react';
import logo from '../../assets/images/ldi.png';

function AppLogo({ size, direction }) {
  const theme = useTheme();
  const dir = direction ? direction : 'column';
  const lStyle = dir === 'column' ? { height: size, width: 'auto' } : { height: 'auto', width: size };

  return (
    <Stack spacing={theme.spacing(1)} justifyContent="center" alignItems="center" direction={dir}>
      {size && <img src={logo} alt={'logo'} style={lStyle} />}
    </Stack>
  );
}

export default AppLogo;
