import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';

export const InputFilterRuleSwitch = ({ label, value, setValue, fullWidth, disabled, ...props }) => {
  const ariaLabel = { inputProps: { 'aria-label': { label } } };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            id={props.id + '-input'}
            key={props.key + '-input'}
            checked={value === 1}
            onChange={(evt) => {
              setValue(evt.target.checked ? 1 : 0);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            fullWidth={fullWidth ?? false}
            disabled={disabled ?? false}
            {...ariaLabel}
            {...props}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
