// types
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { JSONClone } from '../../utils/common';

const initError = {
  code: 0,
  message: ''
};

const init_alert_state = {
  requestId: '',
  apiURL: '',
  showOnlyError: false,
  finished: false,
  succeeded: false,
  pending: false,
  error: JSONClone(initError),
  toShow: false,
  severity: '',
  responseData: null,
  anyError: false
};

// initial state
const initialState = {
  busy: false,
  alertQueue: [],
  customAlertQueue: [],
  customConfirmQueue: []
};

const generateRequestId = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const beginServiceCall = createAsyncThunk('errors/beginServiceCall', async (payload, { rejectWithValue }) => {
  try {
    const reqId = generateRequestId(25);
    const newAlertItem = {
      ...JSONClone(init_alert_state),
      pending: true,
      requestId: reqId,
      apiURL: payload.apiURL,
      showOnlyError: payload.showOnlyError
    };
    return { result: newAlertItem };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const endServiceCall = createAsyncThunk('errors/endServiceCall', async (payload, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const reqId = payload.requestId;
    const res = payload.result;
    let newAlertItem = null;
    let alertItem = state.errors.alertQueue.find((item) => item.requestId === reqId);
    if (alertItem) {
      newAlertItem = {
        ...alertItem,
        finished: true,
        pending: false,
        anyError: res.responseAnyError,
        succeeded: !res.responseAnyError,
        responseData: res.responseData ?? [],
        error: {
          code: res.responseCode,
          message: res.responseMessage
        },
        showInPopup: res.responseShowInPopup ?? false,
        responseApiKey: res.responseApiKey ?? '',
        responseDataRowsCount: res.responseDataRowsCount ?? 0,
        responseDataPagesCount: res.responseDataPagesCount ?? 0,
        responseDataCurrentPage: res.responseDataCurrentPage ?? 0,
        responseDataPageSize: res.responseDataPageSize ?? 0
      };
      if (newAlertItem.finished === true && newAlertItem.succeeded === false) {
        newAlertItem = {
          ...newAlertItem,
          toShow: true,
          severity: 'error'
        };
      } else if (newAlertItem.showOnlyError === false && newAlertItem.finished === true && newAlertItem.succeeded === true) {
        newAlertItem = {
          ...newAlertItem,
          toShow: true,
          severity: 'success'
        };
      }
    }
    return { result: newAlertItem };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const handleErrorServiceCall = createAsyncThunk('errors/handleErrorServiceCall', async (payload, { rejectWithValue }) => {
  try {
    const res = payload.result;
    let newAlertItem = {
      finished: true,
      pending: false,
      anyError: res.responseAnyError,
      succeeded: !res.responseAnyError,
      responseData: res.responseData ?? [],
      error: {
        code: res.responseCode,
        message: res.responseMessage
      },
      showInPopup: res.responseShowInPopup ?? false,
      responseApiKey: res.responseApiKey ?? '',
      responseDataRowsCount: res.responseDataRowsCount ?? 0,
      responseDataPagesCount: res.responseDataPagesCount ?? 0,
      responseDataCurrentPage: res.responseDataCurrentPage ?? 0,
      responseDataPageSize: res.responseDataPageSize ?? 0
    };
    if (newAlertItem.finished === true && newAlertItem.succeeded === false) {
      newAlertItem = {
        ...newAlertItem,
        toShow: true,
        severity: 'error'
      };
    } else if (newAlertItem.showOnlyError === false && newAlertItem.finished === true && newAlertItem.succeeded === true) {
      newAlertItem = {
        ...newAlertItem,
        toShow: true,
        severity: 'success'
      };
    }
    return { result: newAlertItem };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const errors = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    removeDataAlertRequest(state, action) {
      const payload = action.payload;
      const reqId = payload.requestId;
      state.alertQueue = state.alertQueue.filter((item) => item.requestId !== reqId);
    },
    showCustomAlert(state, action) {
      const payload = action.payload;
      let reqId = generateRequestId(25);
      let showCustomState = {
        requestId: reqId,
        open: true,
        message: payload.message,
        severity: payload.severity,
        ready: false
      };
      const newAlertQueue = state.customAlertQueue;
      newAlertQueue.push(showCustomState);
      state.customAlertQueue = newAlertQueue;
    },
    removeCustomRequest(state, action) {
      const payload = action.payload;
      const reqId = payload.requestId;
      state.customAlertQueue = state.customAlertQueue.filter((item) => item.requestId !== reqId);
    },
    showConfirmDialog(state, action) {
      const payload = action.payload;
      let reqId = generateRequestId(25);
      let showCustomState = {
        requestId: reqId,
        open: true,
        title: payload.title,
        message: payload.message,
        handleHide: payload.handleHide,
        handleCancel: payload.handleCancel,
        handleConfirm: payload.handleConfirm
      };
      const newAlertQueue = state.customConfirmQueue;
      newAlertQueue.push(showCustomState);
      state.customConfirmQueue = newAlertQueue;
    },
    hideConfirmDialog(state, action) {
      const payload = action.payload;
      const reqId = payload.requestId;
      state.customConfirmQueue = state.customConfirmQueue.filter((item) => item.requestId !== reqId);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(beginServiceCall.rejected, () => {});
    builder.addCase(beginServiceCall.fulfilled, (state, action) => {
      const newAlertItem = action.payload.result;
      state.alertQueue = [...state.alertQueue, newAlertItem];
    });
    builder.addCase(endServiceCall.rejected, () => {});
    builder.addCase(endServiceCall.fulfilled, (state, action) => {
      const newAlertItem = action.payload.result;
      let newAlertQueue = [...state.alertQueue];
      // se non serve visualizzare nessun messaggio elimino la richiesta
      if (!newAlertItem.toShow) {
        newAlertQueue = newAlertQueue.filter((item) => item.requestId !== newAlertItem.requestId);
      } else {
        let alertItemIndex = newAlertQueue.findIndex((item) => item.requestId === newAlertItem.requestId);
        if (alertItemIndex !== -1) {
          newAlertQueue[alertItemIndex] = newAlertItem;
        }
      }
      state.alertQueue = newAlertQueue;
      state.busy = newAlertQueue.filter((item) => item.pending === true).length > 0;
    });
    builder.addCase(handleErrorServiceCall.fulfilled, (state, action) => {
      const newAlertItem = action.payload.result;
      let newAlertQueue = [...state.alertQueue];
      // se non serve visualizzare nessun messaggio elimino la richiesta
      if (!newAlertItem.toShow) {
        newAlertQueue = newAlertQueue.filter((item) => item.requestId !== newAlertItem.requestId);
      } else {
        let alertItemIndex = newAlertQueue.findIndex((item) => item.requestId === newAlertItem.requestId);
        if (alertItemIndex !== -1) {
          newAlertQueue[alertItemIndex] = newAlertItem;
        }
      }
      state.alertQueue = newAlertQueue;
      state.busy = newAlertQueue.filter((item) => item.pending === true).length > 0;
    });
  }
});

export default errors.reducer;

export const { removeDataAlertRequest, showCustomAlert, removeCustomRequest, showConfirmDialog, hideConfirmDialog } = errors.actions;
