import { Box, styled, useTheme } from '@mui/material';
import React from 'react';

export const StyledChartBox = ({ ...props }) => {
  const theme = useTheme();

  const StyledComponent = styled(Box)({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  });

  return <StyledComponent>{props.children}</StyledComponent>;
};
